import "./App.css";

function App() {
  return (
    <div className="p-0 m-o relative top-0 left-0 flex-col justify-center w-full">
      <div className="w-full h-screen flex flex-col justify-between align-middle items-center">
        <div className="flex pt-96 text-5xl font-extrabold text-lime-700">
          Coming soon ...
        </div>
        <a href="#intro" className="flex flex-col p-5 gap-5 pb-20 justify-center items-center">
          <div className="text-lime-900">Company Profile</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 text-lime-700"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </a>
      </div>
      <img
        id="intro"
        src="/images/intro.webp"
        className="flex w-full h-auto"
        alt="intro"
      />
      <img
        src="/images/page_1.webp"
        className="flex w-full h-auto"
        alt="page_1"
      />
      <img
        src="/images/page_2.webp"
        className="flex w-full h-auto"
        alt="page_2"
      />
      <img
        src="/images/page_3.webp"
        className="flex w-full h-auto"
        alt="page_3"
      />
      <img
        src="/images/page_4.webp"
        className="flex w-full h-auto"
        alt="page_4"
      />
      <img
        src="/images/page_5.webp"
        className="flex w-full h-auto"
        alt="page_5"
      />
      <img
        src="/images/page_6.webp"
        className="flex w-full h-auto"
        alt="page_6"
      />
      <img
        src="/images/page_7.webp"
        className="flex w-full h-auto"
        alt="page_7"
      />
      <img
        src="/images/page_8.webp"
        className="flex w-full h-auto"
        alt="page_8"
      />
      <img
        src="/images/page_9.webp"
        className="flex w-full h-auto"
        alt="page_9"
      />
      <img
        src="/images/page_10.webp"
        className="flex w-full h-auto"
        alt="page_10"
      />
      <img
        src="/images/page_11.webp"
        className="flex w-full h-auto"
        alt="page_11"
      />
    </div>
  );
}

export default App;
